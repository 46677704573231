<template>
  <div class="cola">
    <div class="cola_center">
      <!-- 公告文档 -->
      <h2>{{cdata.title}}</h2>
      <p>{{cdata.content}}</p>
      <div class="file">
        <i></i>
        <span>{{cdata.fujian}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cdata: {
      type: Object,
      default: null,
    },
  },
  creatd() {
    console.log(this.showdata);
  },
};
</script>
<style lang="scss" scoped>
.cola {
  .cola_center {
    background-color: #fff;
    padding: 15px;
    h2 {
      border-left: 3px solid #4db4d2;
      padding-left: 5px;
      // width: 384px;
      // height: 21px;
      font-size: 15px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #141516;
      // line-height: 19px;
    }
    p {
      min-height: 400px;
      margin-top: 10px;
      // width: 112px;
      // height: 21px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #5d6066;
      // line-height: 19px;
    }
    .file {
      //   width: 1160px;
      //   height: 32px;
      background: rgba(25, 119, 249, 0.06);
      border-radius: 4px 4px 4px 4px;
      //   opacity: 1;
      i {
        display: inline-block;
        width: 22px;
        height: 22px;
        background: url("../../../../assets/active/file.png") no-repeat;
        vertical-align: middle;
      }
      span {
        // width: 137px;
        // height: 18px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #141516;
        // line-height: 16px;
        vertical-align: middle;
      }
    }
  }
}
</style>