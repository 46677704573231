<template>
  <div class="doc">
    <div class="doc_center">
      <!-- 资料收集 -->
      <p>{{cdata.title}}</p>
      <el-table :data="cdata.list" stripe style="width: 100%">
        <el-table-column prop="name" label="名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="suer" label="上传者" width="150"></el-table-column>
        <el-table-column prop="time" label="上传时间" width="150"></el-table-column>
        <el-table-column prop="sizer" label="文件大小" width="150"></el-table-column>
        <el-table-column prop="numbers" label="下载次数" width="150"></el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="curage"
        :page-size="pagesize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cdata: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // 数据列表
      // 分页
      curage: 1,
      pagesize: 10,
      total: this.cdata.list.length,
    };
  },
  created() {},
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.doc {
  .doc_center {
    padding: 15px;
    p {
      //   width: 384px;
      //   height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #141516;
      //   line-height: 19px;
      border-left: 3px solid #f2b44d;
      padding-left: 5px;
    }
    .el-table {
        margin-top: 10px;
    }
    .el-pagination {
      padding: 15px 0;
      display: flex;
      justify-content: center;
    }
  }
}
</style>