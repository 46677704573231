<template>
  <div class="ques">
    <div class="ques_center">
      <h2>{{cdata.title}}</h2>
      <main v-for="item in cdata.list" :key="item.id">
        <div v-if="item.qtype=='单选题'">
          <p>
            <span>{{item.questions}}</span>
            <span style="display: inline-block; margin-left:30px">({{item.qtype}})</span>
          </p>
          <div>
            <el-radio-group v-model="votefrom[item.value]">
              <div class="xiaozu">
                <el-radio v-for="items in item.answer" :label="items.text" :key="items.id"></el-radio>
              </div>
            </el-radio-group>
          </div>
        </div>
        <div v-if="item.qtype=='多选题'">
          <p>
            <span>{{item.questions}}</span>
            <span style="display: inline-block; margin-left:30px">({{item.qtype}})</span>
          </p>
          <el-checkbox-group v-model="checkall">
            <div class="xiaozu">
              <el-checkbox v-for="items in item.answer" :label="items.text" :key="items.id">{{items.text}}</el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
        <div v-if="item.qtype=='问答'">
          <p>
            <span>{{item.questions}}</span>
            <span style="display: inline-block; margin-left:30px">({{item.qtype}})</span>
          </p>
          <div class="wenbenyu">
            <el-input type="textarea" v-model="votefrom[item.value]"></el-input>
          </div>
        </div>
      </main>
      <el-button type="primary" @click="postfrom" size="mini">提交投票</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cdata: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      votefrom: {},
      // 多选框
      checkall: [],
    };
  },
  created() {},
  methods: {
    //
    postfrom() {
      console.log(this.votefrom, "提交表单");
    },
  },
};
</script>
<style lang="scss" scoped>
.ques {
  .ques_center {
    background-color: #fff;
    padding: 15px;

    h2 {
      //   width: 320px;
      //   height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #141516;
      //   line-height: 19px;
      border-left: 3px solid #ff8687;
      padding-left: 5px;
    }
    main {
      margin-top: 20px;
      .el-radio-group,
      .el-checkbox-group {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        .xiaozu {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .el-radio {
            height: 16px;
            line-height: 16px;
            margin-top: 10px;
          }
          .el-checkbox {
            height: 16px;
            line-height: 16px;
            margin-top: 10px;
          }
        }
      }
      .el-button {
        margin-top: 10px;
      }
      .wenbenyu{
        margin-top: 20px;
      }
    }
    .el-button {
      margin-top: 20px;
    }
  }
}
</style>