<template>
  <div class="vote">
    <div class="vote_center">
      <h2>{{cdata.title}}</h2>
      <main v-for="item in cdata.list" :key="item.id">     
        <p>
          <span>{{item.questions}}</span>
          <span style="display: inline-block; margin-left:30px">({{item.qtype}})</span>
        </p>
        <el-radio-group v-model="votefrom[item.value]">
          <div class="xiaozu">
            <el-radio v-for="items in item.answer" :label="items.text" :key="items.id"></el-radio>
          </div>
          <div class="baifenbi" v-if="item.showdo">
            <el-progress :percentage="itemss.value" v-for="itemss in item.perdata" :key="itemss.id"></el-progress>
          </div>
        </el-radio-group>
      </main>
      <el-button type="primary" @click="postfrom" v-if="cdata.showstatus" size="mini">提交投票</el-button>
      <el-button type="primary" disabled v-else size="mini">已提交</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cdata: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      votefrom: {
        // radio: null,
      },
    };
  },
  methods: {
    postfrom() {
      console.log(this.votefrom, "提交表单");
    },
  },
};
</script>
<style lang="scss" scoped>
.vote {
  .vote_center {
    background-color: #fff;
    padding: 15px;

    h2 {
      //   width: 320px;
      //   height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #141516;
      //   line-height: 19px;
      border-left: 3px solid #7678e8;
      padding-left: 5px;
    }
    main {
      margin-top: 20px;
      .el-radio-group {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        .xiaozu {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .el-radio {
            height: 16px;
            line-height: 16px;
            margin-top: 10px;
          }
        }
        .baifenbi {
          // line-height: 19px;
          width: 30%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .el-progress {
            margin-top: 10px;
            height: 16px;
            line-height: 16px;
          }
        }
      }
    }
    .el-button {
      margin-top: 20px;
    }
  }
}
</style>