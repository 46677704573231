<template>
  <div class="showactive">
    <div class="showactive_center">
      <!-- <h1>标题</h1> -->
      <nav>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/activity' }">通知公告</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/activityintroduce', query: { id: this.$route.query.id}, }">{{breadtitle}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{breadtitle}}</el-breadcrumb-item>
        </el-breadcrumb>
      </nav>
      <main>
        <div class="m_header">
          <div>
            <i v-if="showdata.type=='公告文档'" class="one"></i>
            <i v-if="showdata.type=='资料收集'" class="two"></i>
            <i v-if="showdata.type=='投票'" class="three"></i>
            <i v-if="showdata.type=='调查问卷'" class="four"></i>
            <span>{{showdata.type}}</span>
          </div>
          <div class="quanuqna">
            <p
              :class="index==nowindex?'black':'light'"
              v-for="item,index in showalldata"
              :key="item.id"
              @click="cutdata(index,item)"
            >{{index+1}}</p>
          </div>
        </div>
        <cola v-if="showdata.type=='公告文档'" :cdata="this.showdata"></cola>
        <doc v-if="showdata.type=='资料收集'" :cdata="this.showdata"></doc>
        <vote v-if="showdata.type=='投票'" :cdata="this.showdata"></vote>
        <ques v-if="showdata.type=='调查问卷'" :cdata="this.showdata"></ques>
      </main>
      <footer>
        <h3>评论评价</h3>
        <div class="det_foot">
          <el-input type="textarea" :rows="5" placeholder="发表您的看法......" v-model="textarea"></el-input>
          <el-button type="primary" @click="releaseComment" size="mini" disable>发表评论</el-button>
        </div>
        <div class="s_text" v-for="item in evalData" :key="item.id">
          <div class="text_left">
            <div class="member_flex" v-if="item.avager==null">
              <img src="../../../assets/components/userin.png" alt />
            </div>
            <div class="member_flex" v-else>
              <img :src="item.avager" alt />
            </div>
            <p class="user_name">{{item.createUser}}</p>
          </div>
          <div class="text_right">
            <div class="r_content">
              <p>{{item.message}}</p>
            </div>
            <div class="r_time">
              <span>{{item.createTime}}</span>
            </div>
          </div>
        </div>
        <div class="block" v-if="this.evalData!==null">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage1"
            :page-size="5"
            layout="total, prev, pager, next, jumper"
            :total="total"
            background
          ></el-pagination>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import cola from "./com/col.vue";
import doc from "./com/doc.vue";
import ques from "./com/ques.vue";
import vote from "./com/vote.vue";
export default {
  data() {
    return {
      // 面包屑
      breadtitle: null,
      // 详情
      showalldata: null, //全部数据
      showdata: null, //单个数据
      nowindex: null,
      // 发表评论
      textarea: null,
      //评论列表
      evalData: null,
      // 分页
      total: null,
      currentPage1: 1, //页数
    };
  },
  created() {
    console.log(this.$route.query.title);
    // 面包屑
    this.breadtitle = this.$route.query.title;
    //获取数据
    this.querydata();
    // 显示数据
    this.cutdata(
      this.$route.query.index,
      this.showalldata[this.$route.query.index]
    );
    //
    this.queryCourseComment(this.$route.query.ids); //获取评论
  },
  methods: {
    // 获取数据
    querydata() {
      console.log(this.$route.query.id, "获取通知公告详情");
      let aid = this.$route.query.id;
      let aa = [
        {
          id: 1,
          type: "公告文档",
          title: "朝阳区一体化教研系统一期上线试运行",
          content:
            "朝阳区一体化教研系统一期上线试运行。本期系统上线功能包括教研圈，课程上传，资料上传等功能。请各位老师及教研员使用，后续功能会在二期工作中陆续上线。",
          fujian: "一体化.ZIP",
        },
      ];
      let ab = [
        {
          id: 2,
          type: "资料收集",
          title: "一体化系统上线试运行问题反馈收集",
          list: [
            {
              id: 1,
              type: "资料收集",
              title: "收集资料",
              name: "收集课程资料",
              suer: "刘老师",
              time: "2022-06-28",
              sizer: "123M",
              numbers: "12次",
            },
          ],
        },
      ];
      let ac = [
        {
          id: 3,
          type: "投票",
          title: "一体化系统上线试运行满意度投票反馈",
          showstatus: true, //投票百分数是否显示
          list: [
            {
              id: 1,
              questions: "请您对一体化系统上线后的使用满意度进行投票",
              qtype: "单选题",
              showdo: false,
              value: "first",
              answer: [
                {
                  id: 1,
                  text: "非常满意",
                },
                {
                  id: 2,
                  text: "比较满意",
                },
                {
                  id: 3,
                  text: "表现一般",
                },
                {
                  id: 4,
                  text: "不太满意",
                },
              ],
              perdata: [
                { id: 1, value: 50 },
                { id: 2, value: 60 },
                { id: 3, value: 70 },
                { id: 4, value: 80 },
              ],
            },
          ],
        },
      ];
      let ad = [
        {
          id: 1,
          type: "调查问卷",
          title: "一体化系统上线试运行问卷调查",
          list: [
            {
              id: 1,
              questions: "您的身份是？",
              qtype: "单选题",
              value: "first",
              answer: [
                {
                  id: 1,
                  text: "教师",
                  value: "50",
                },
                {
                  id: 2,
                  text: "教研员",
                },
              ],
            },
            {
              id: 2,
              questions: "您对一体化系统上线的功能操作流程是否掌握",
              qtype: "多选题",
              // value: 'second',
              answer: [
                {
                  id: 1,
                  text: "熟练掌握 ",
                },
                {
                  id: 2,
                  text: "一般掌握 ",
                },
                {
                  id: 3,
                  text: "不太掌握",
                },
              ],
            },
            {
              id: 3,
              questions: "您觉得一体化系统一期上线的功能，对您是否有帮助",
              qtype: "问答",
              value: "first",
            },
          ],
        },
      ];
      // let af = [
      //   {
      //     id: 1,
      //     type: "公告文档",
      //     title: "朝阳区一体化教研系统一期上线试运行",
      //     content:
      //       "朝阳区一体化教研系统一期上线试运行。本期系统上线功能包括教研圈，课程上传，资料上传等功能。请各位老师及教研员使用，后续功能会在二期工作中陆续上线。（若有使用文档可提示下载使用文档）",
      //     fujian: "一体化.ZIP",
      //   },
      //   {
      //     id: 2,
      //     type: "资料收集",
      //     title: "一体化系统上线试运行问题反馈收集（可参考西城研修网捉虫活动）",
      //     list: [
      //       {
      //         id: 1,
      //         type: "资料收集",
      //         title: "收集资料",
      //         name: "收集课程资料",
      //         suer: "刘老师",
      //         time: "2022-06-28",
      //         sizer: "123M",
      //         numbers: "12次",
      //       },
      //     ],
      //   },
      //   {
      //     id: 3,
      //     type: "投票",
      //     title: "一体化系统上线试运行满意度投票反馈",
      //     showstatus: true, //投票百分数是否显示
      //     list: [
      //       {
      //         id: 1,
      //         questions: "请您对一体化系统上线后的使用满意度进行投票",
      //         qtype: "单选题",
      //         showdo: false,
      //         value: "first",
      //         answer: [
      //           {
      //             id: 1,
      //             text: "非常满意",
      //           },
      //           {
      //             id: 2,
      //             text: "比较满意",
      //           },
      //           {
      //             id: 3,
      //             text: "表现一般",
      //           },
      //           {
      //             id: 4,
      //             text: "不太满意",
      //           },
      //         ],
      //         perdata: [
      //           { id: 1, value: 50 },
      //           { id: 2, value: 60 },
      //           { id: 3, value: 70 },
      //           { id: 4, value: 80 },
      //         ],
      //       },
      //       // {
      //       //   id: 2,
      //       //   questions: "第二个问题",
      //       //   qtype: "单选题",
      //       //   showdo: false,

      //       //   value: "second",
      //       //   answer: [
      //       //     {
      //       //       id: 1,
      //       //       text: "选项A",
      //       //     },
      //       //     {
      //       //       id: 2,
      //       //       text: "选项B",
      //       //     },
      //       //     {
      //       //       id: 3,
      //       //       text: "选项C",
      //       //     },
      //       //     {
      //       //       id: 4,
      //       //       text: "选项D",
      //       //     },
      //       //   ],
      //       //   perdata: [
      //       //     { id: 1, value: 50 },
      //       //     { id: 2, value: 60 },
      //       //     { id: 3, value: 70 },
      //       //     { id: 4, value: 80 },
      //       //   ],
      //       // },
      //     ],
      //   },
      //   {
      //     id: 4,
      //     type: "调查问卷",
      //     title: "一体化系统上线试运行问卷调查",
      //     list: [
      //       {
      //         id: 1,
      //         questions: "您的身份是？",
      //         qtype: "单选题",
      //         value: "first",
      //         answer: [
      //           {
      //             id: 1,
      //             text: "教师",
      //             value: "50",
      //           },
      //           {
      //             id: 2,
      //             text: "教研员",
      //           },
      //         ],
      //       },
      //       {
      //         id: 2,
      //         questions: "您对一体化系统上线的功能操作流程是否掌握",
      //         qtype: "多选题",
      //         // value: 'second',
      //         answer: [
      //           {
      //             id: 1,
      //             text: "熟练掌握 ",
      //           },
      //           {
      //             id: 2,
      //             text: "一般掌握 ",
      //           },
      //           {
      //             id: 3,
      //             text: "不太掌握",
      //           },
      //         ],
      //       },
      //       {
      //         id: 3,
      //         questions: "您觉得一体化系统一期上线的功能，对您是否有帮助",
      //         qtype: "问答",
      //         value: "first",
      //       },
      //     ],
      //   },
      // ];
      if (aid == 1) {
        this.showalldata = aa;
      } else if (aid == 2) {
        this.showalldata = ab;
      } else if (aid == 3) {
        this.showalldata = ac;
      } else if (aid == 4) {
        this.showalldata = ad;
      }
    },
    // 切换数据源
    cutdata(num, item) {
      console.log(num, item, "切换数据");
      this.nowindex = num;
      this.showdata = item;
    },
    // 获取评论
    queryCourseComment() {
      console.log("uuu");
      let aa = [
        {
          id: 1,
          avager: null,
          createUser: "刘老师",
          message: "评论内容",
          createTime: "2022-07-08",
        },
      ];
      this.evalData = aa;
      this.total = aa.length;
    },
    //发表评论
    releaseComment() {
      console.log("发表评论");
    },

    // 分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage1 = val;
      this.queryCourseComment(this.$route.query.ids);
    },
  },
  components: {
    cola,
    doc,
    ques,
    vote,
  },
};
</script>
<style lang="scss" scoped>
.showactive {
  .showactive_center {
    width: 1200px;
    margin: 0 auto;
    nav {
      padding: 15px 0;
    }
    main {
      padding: 20px;
      background-color: #fff;
      .m_header {
        padding: 0 0 10px 0;
        border-bottom: 1px solid #d7dce4;
        display: flex;
        justify-content: space-between;
        .quanuqna {
          text-align: center;
          // width: 30px;
          // height: 30px;
          line-height: 30px;
          // opacity: 1;
          display: flex;
          justify-content: flex-start;
          cursor: pointer;
          p {
            margin-left: 5px;
            width: 30px;
            height: 30px;
            // width: 9px;
            // height: 18px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            // line-height: 14px;
            border: 1px solid #bec3cc;
            border-radius: 50%;
          }
          .light {
            background-color: #fff;
            color: #bec3cc;
          }
          .black {
            background-color: #1977f9;
            color: #fff;
          }
          p:hover {
            color: #fff;
            background: rgba(25, 119, 249, 0.5);
          }
        }

        i {
          display: inline-block;
          vertical-align: middle;
          margin-left: 5px;
        }
        .one {
          width: 24px;
          height: 24px;
          background: url("../../../assets/active/one.png") no-repeat;
        }
        .two {
          width: 22px;
          height: 21px;
          background: url("../../../assets/active/two.png") no-repeat;
        }
        .three {
          width: 21px;
          height: 21px;
          background: url("../../../assets/active/three.png") no-repeat;
        }
        .four {
          width: 21px;
          height: 21px;
          background: url("../../../assets/active/four.png") no-repeat;
        }
        span {
          //   width: 72px;
          //   height: 24px;
          font-size: 18px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #141516;
          //   line-height: 21px;
          display: inline-block;
          margin-left: 4px;
          vertical-align: middle;
        }
      }
      min-height: 520px;
    }
    footer {
      margin-top: 20px;
      padding: 10px 20px;
      background-color: #fff;
      h3 {
        padding: 10px 0;
        border-bottom: 1px solid #d7dce4;
      }
      .det_foot {
        margin-top: 20px;
        .el-button {
          margin-top: 10px;
          float: right;
        }
        .el-textarea {
          ::v-deep .el-textarea__inner {
            background: rgba(25, 119, 249, 0.06);
          }
        }
      }
      .s_text {
        width: 1140px;
        height: 110px;
        padding: 20px 10px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7dce4;

        .text_left {
          width: 150px;
          height: 110px;
          .member_flex {
            display: flex;
            justify-content: center;
          }
          .user_name {
            margin-top: 10px;
            text-align: center;
            // width: 42px;
            // height: 18px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #141516;
            // line-height: 16px;
          }
        }
        .text_right {
          width: 1039px;
          height: 110px;
          padding: 0 15px;
          .r_content {
            height: 70px;
            p {
              // width: 98px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5d6066;
              // line-height: 16px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
          }
          .r_time {
            // height: 10px;
            display: flex;
            justify-content: flex-end;
            // width: 67px;
            // height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #8d9199;
            // line-height: 14px;
          }
        }
      }
      .block {
        padding: 20px 10px 0;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>